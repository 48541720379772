<template>
  <div class="card">

      <b-tabs v-model="currentTab" type="is-toggle" expanded :class="{'without-tenants': !isTenantEditor}">
        <b-tab :title="this.$t('tenants.users')" @click="setSelectedTab(0)">
              <div class="page-users">
                <div class=" py-3 px-3 d-none d-lg-block">
                  <div class="card-header px-0">
                    <h5>{{ $t('user.users') }}</h5>
                    <div class="card-header-right"><button class="btn btn-primary" @click="createUser" style="text-transform: none">{{ $t('settings.Create_user') }}</button></div>
                  </div>

                  <div class="block">
                    <div style="display: flex; width: 100%; align-items: stretch; justify-content: space-between; margin-bottom: 1rem; margin-top: 1rem">
                      <div class="user-form-field" style="min-width: 80%;max-width: 80%">
                        <multiselect
                            v-model="selectedColumns"
                            :options="columns"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            @input="sort"
                            track-by="name"
                            selectLabel=""
                            deselectLabel=""
                            selectedLabel=""
                            :placeholder="$t('users_list_params.colvis')"
                            label="name">
                          <span slot="noResult">{{ $t('user.no_result') }}</span>

                          <template slot="tag" slot-scope="{option, remove}">
                            <span class="multiselect__tag" :class="{'notRemove' : option.key === 'login' || option.key === 'button'}">
                              <span>{{$t('users_list_params.'+ option.label)}}</span>
                              <i tabindex="1" v-if="option.key !== 'login' && option.key !== 'button'" class="multiselect__tag-icon" @click="remove(option)"></i>
                            </span>
                          </template>
                        </multiselect>
                      </div>
                      <div class="filter-users">
                        <b-form-input
                            style="height: 100%"
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            class="search-filter"
                        ></b-form-input>
                      </div>
                    </div>

                    <b-table
                        style="max-height: calc(100vh - 25rem)"
                        sticky-header
                        head-variant="light"
                        :filter="filter"
                        :items="selectedColumns.length ? items : []"
                        :fields="selectedColumns"
                        :empty-text="$t('billing.empty_text')"
                        responsive
                        show-empty
                        small
                        :busy="isLoading"
                        @row-clicked="showUserDetails"
                    >
                      <template #table-busy>
                        <div class="text-center text-primary my-2">
                          <b-spinner class="align-middle"></b-spinner>
                        </div>
                      </template>
                      <template #head()="scope">
                        <!--            {{scope}}-->
                        {{ $t('users_list_params.'+scope.label)}}
                      </template>
                      <template #cell(first_name)="row">
                        {{ row.item.first_name }} <br> {{ row.item.last_name }}
                      </template>
                      <template #cell(login)="row">
                        {{ row.value }}
                      </template>
                      <template #cell(account.balance)="row">
                        <div v-if="!row.item.account.unlimited">{{ row.item.account.balance }}</div>
                        <div v-else>{{ $t('user.unlimited') }}</div>
                      </template>
                      <template #cell(account.limit)="row">
                        <span style="display: flex; flex-direction: column;justify-content: center">
                          <span>{{ $t('users_list_params.credits') }}: {{row.item.account.limit}}</span>
                          <span>{{ $t('users_list_params.days') }}: {{row.item.account.period}}</span>
                        </span>
                      </template>
                      <template #cell(account.expired_at)="row">
                        <div v-if="row.item.account.is_active"> {{ moment(row.item.account.expired_at, 'X').format('DD.MM.YYYY') }} <br></div>
                        <div v-else class="text-danger">{{ $t('users_list_params.inactive') }}</div>
                      </template>
                      <template #cell(roles)="row">
                        {{ typeof row.item.roles !== 'undefined' ? row.item.roles.map(role => $t('user.'+role)).join(', '): '' }}
                      </template>
                      <template #cell(created_by)="row">
                        {{ row.value || '' }}
                      </template>
                      <template #cell(created_at)="row">
                        <div>{{ moment(row.value, 'X').format('DD.MM.YYYY, HH:mm') }}</div>
                      </template>
                      <template #cell(last_location)="row">
                        <div v-if="row.value">
                          {{row.value.country}}, {{row.value.region}},
                          {{row.value.city}}, <br> ({{row.value.latitude}},{{row.value.longitude}})
                        </div>
                      </template>
                      <template #cell(permissions)="row">
                        <div style="display: flex;flex-direction: column">
                          <span v-for="(permission,k) in row.value" :key="k">
                            {{$t(`permissions.${permission}`)}}{{row.value.length-1 === k ? '':', '}}
                          </span>
                        </div>
                      </template>
                      <template #cell(note)="row">
                        <div style="max-width: 20vh; white-space: break-spaces">{{ row.value }}</div>
                      </template>
                      <template #cell(last_search_at)="row">
                        <div v-if="row.value">
                          {{ moment(row.value, 'X').format('DD.MM.YYYY, HH:mm') }}
                        </div>
                      </template>
                      <template #cell(setup_count)="row">
                        {{ row.value || 0}}
                      </template>
                      <template #cell()="row">
                        <div class="text-sm-center">
                          <button class="btn btn-primary"
                                  @click="redirectUser(row.item.id)"
                                  :content="$t('settings.Edit')"
                                  v-tippy="{ placement : 'top',  arrow: true }">
                            <i class="feather icon-edit "></i>
                          </button>
                        </div>
                      </template>
                    </b-table>
                  </div>
                </div>
                <div class="d-block d-lg-none">
                  <div class="card">

                    <div class="card-header">
                      <h5 style="font-size: 18px; font-weight: 700">
                        {{ $t('user.users') }}
                      </h5>
                    </div>
                    <div style="display:flex; justify-content: center; margin-bottom: 12px;"><button class="btn btn-primary" @click="createUser" style="text-transform: none">{{ $t('settings.Create_user') }}</button></div>

                    <div class="container">
                      <!--          <div v-for="user in users" :key="user.id">-->
                      <user-item-mobile v-for="user in users" :key="user.id" :user="user"/>

                    </div>
                  </div>
                </div>
              </div>
        </b-tab>
        <b-tab v-if="isTenantEditor" :title="this.$t('tenants.organization')" @click="setSelectedTab(1)">
          <div>
              <list></list>
          </div>
        </b-tab>
      </b-tabs>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Multiselect from "vue-multiselect";
import moment from "moment";
import UserItemMobile from "@/components/settings/userItemMobile.vue";
import i18n from "@/i18n";
import List from "@/components/tenants/list";


export default {
  name: "UsersTable",
  setup() {
    return {
      moment
    }
  },
  components: {
    List,
    UserItemMobile,
    Multiselect
  },
  data() {
    return {
      filter: null,
      filterOn: [],
      selectedColumns: [],
      isLoading: false,
      currentTab: this.$store.state.tenants.selectedTab,
      currentPage: 1,
      payload: {
          order_by: 'created_at',
          order_type: 'DESC',
          addToList: true,
          filters: {
              search: "",
              created_from: undefined,
              created_to: undefined,
          },
          limit: 300,
          offset: 0
      },
    }
  },
  mounted() {
    if (localStorage.getItem('userListSelectedColumns')){
      this.selectedColumns = JSON.parse(localStorage.getItem('userListSelectedColumns'))
    } else {
      this.setDefaultColumns();
    }

    this.$store.dispatch('auth/getMe');
    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);
    this.isLoading = true;
    this.$store.dispatch('users/clearUsersList');
    this.getList();
  },

  computed: {
    ...mapGetters('users', [
      'users', 'totalUsers'
    ]),
    ...mapGetters('auth', ['isTenantEditor']),
    ...mapGetters('tenants', ['selectedTab', 'countTenants']),
    items(){
      return this.users
    },
    columns(){
      return  [
          {
            index: 1,
            key: 'first_name',
            name: this.$t('users_list_params.Name'),
            label: 'Name',
            sortable: true
          },
          {
            index: 2,
            key: 'login',
            name: this.$t('users_list_params.Login'),
            label: "Login",
            $isDisabled: true,
            sortable: true
          },
          {
            index: 3,
            key: 'account.balance',
            name: this.$t('users_list_params.balance'),
            label: 'balance',
            sortable: true
          },
          {
            index: 4,
            key: 'account.limit',
            name: this.$t('users_list_params.limit'),
            label: "limit",
            sortable: true
          },
          {
            index: 5,
            key: 'account.expired_at',
            name: this.$t('users_list_params.expired_at'),
            label: 'expired_at',
            sortable: true
          },
          {
            index: 6,
            key: 'roles',
            name: this.$t('users_list_params.roles'),
            label: 'roles',
            sortable: true
          },
          {
            index: 7,
            key: 'created_by',
            name: this.$t('users_list_params.created_by'),
            label: 'created_by',
            sortable: true
          },
          {
            index: 7,
            key: 'created_at',
            name: this.$t('users_list_params.Created'),
            label: 'Created',
            sortable: true
          },
          {
            index: 8,
            key: 'last_location',
            name: this.$t('users_list_params.geolocation'),
            label: 'last_location',
            sortable: false
          },
          {
            index: 9,
            key: 'permissions',
            name: this.$t('users_list_params.permissions'),
            label: 'permissions',
            sortable: false
          },
          {
            index: 10,
            key: 'note',
            name: this.$t('users_list_params.note'),
            label: 'note',
            sortable: false
          },
          {
            index: 11,
            key: 'last_search_at',
            name: this.$t('users_list_params.Last_install'),
            label: 'Last_install',
            sortable: true
          },
          {
            index: 12,
            key: 'setup_count',
            name: this.$t('users_list_params.setup_count'),
            label: 'setup_count',
            sortable: true
          },
          {
            index: 13,
            key: 'button',
            name: this.$t('users_list_params.actions'),
            label: 'actions',
            $isDisabled: true,
            sortable: false
          }
        ]
    },

  },
  watch: {
    currentPage(val){
      this.payload.offset = (val - 1) * this.payload.limit;
      this.isLoading = true;
      if(this.payload.offset >= this.totalUsers){
        this.isLoading = false;
      } else {
        this.getList();
      }
    }
  },
  methods:{
    setSelectedTab(id){
      this.$store.dispatch('tenants/selectedTab', id)
    },
    showUserDetails(item){
      this.$router.push({name: 'UserDetail', params: {id: item.id}})
    },

    setSelectedColumns(arr){
      localStorage.setItem('userListSelectedColumns',JSON.stringify(arr));
    },
    setDefaultColumns(){
      let arr = [
        {
          index: 1,
          key: 'first_name',
          name: this.$t('users_list_params.Name'),
          label: 'Name',
          sortable: true
        },
        {
          index: 2,
          key: 'login',
          name: this.$t('users_list_params.Login'),
          label: 'Login',
          sortable: true
        },
        {
          index: 3,
          key: 'account.balance',
          name: this.$t('users_list_params.balance'),
          label: 'balance',
          sortable: true
        },
        {
          index: 4,
          key: 'account.limit',
          name: this.$t('users_list_params.limit'),
          label: 'limit',
          sortable: true
        },
        {
          index: 5,
          key: 'account.expired_at',
          name: this.$t('users_list_params.expired_at'),
          label: 'expired_at',
          sortable: true
        },
        {
          index: 6,
          key: 'roles',
          name: this.$t('users_list_params.roles'),
          label: 'roles',
          sortable: true
        },
        {
          index: 7,
          key: 'created_by',
          name: this.$t('users_list_params.created_by'),
          label: 'created_by',
          sortable: true
        },
        {
          index: 13,
          key: 'button',
          name: this.$t('users_list_params.actions'),
          label: 'actions',
          sortable: false
        },
      ]
      localStorage.setItem('userListSelectedColumns', JSON.stringify(arr));
      this.selectedColumns = arr;
    },


    sort(){
      if (this.selectedColumns.length){
        let arr = []
        arr = this.selectedColumns.sort(function (a, b){ return a.index - b.index })
        this.selectedColumns = arr
        this.setSelectedColumns(arr);
      }
    },
    redirectUser(id) {
      this.$router.push({name: 'UserEdit', params: {id}})
    },
    updateUsers(){
      this.isLoading = true;
    },
    createUser() {
      this.$router.push({name: 'UserCreate'})
    },
    getList() {
      this.$store.dispatch('users/getUsersList', this.payload).then(() => {
        this.isLoading = false;
        this.currentPage++;
      })
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.notRemove{
  padding-right: 10px;
}
.b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(43, 43, 43, 0.1), 0 11px 6px -7px rgba(43, 43, 43, 0.1);
}
.multiselect__option--selected:after {
  display: inline-block;
  content: "";
  height: 40px;
  width: 15px;
  background-color: #f3f3f3;
  background-image: url("/images/check-solid.svg");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: center;
}
.multiselect__option--selected.multiselect__option--highlight {
  background-color: #d7d7d7;
  color: #35495e;
}
.multiselect__option--selected.multiselect__option--highlight:after {
  background-color: #d7d7d7;
  background-image: url("/images/check-solid.svg");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: center;
}
.multiselect__option--highlight {
  background-color: #f3f3f3;
  color: #35495e;
}
.multiselect__tags {
  border-radius: 2px;
  border: 1px solid rgb(204, 204, 204);
}
.multiselect__tag {
  background: #d9ebff;
  color: #35495e;
}
.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
  background: #5aa8fb;

}
.table.table-sm td{
  padding: 6px !important;
}
.page-users td {
  cursor: pointer;
}
.without-tenants .nav-tabs {
  display: none;
}
.card .page-users .card-header .card-header-right {
  right: 0;
  width: 220px;
}
.card .page-users .card-header .card-header-right button {
  width: 100%;
}
.filter-users {
  width: 220px;
}
</style>
<style lang="scss">
.nav-tabs .nav-link{
  color: #4099ff;
}
</style>